import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TopBar from "../../components/TopBar/TopBar";
import SectionOne from "../../pages/Home/TopSection/TopSection";
import SectionTwo from "../../pages/Home/AppsSection/AppsSection";
import SectionThree from "../../pages/Home/ContactSection/ContactSection";

const AppContainer = styled.div`
  font-family: "Arial", sans-serif;
  background-color: black; /* Set the background color to black */
  overflow-x: hidden; /* Hide horizontal overflow */
`;

function HomePage() {
  return (
    <AppContainer>
      <SectionOne id="home" />
      <SectionTwo id="cfn" />
      <SectionThree id="contact" />
    </AppContainer>
  );
}

export default HomePage;
