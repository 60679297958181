import React from "react";
import AppStoreImage from "../../assets/buttons/appstore.png";
import GooglePlayImage from "../../assets/buttons/googleplay.png";

function StoreButton({ type, url }) {
  const handleClick = () => {
    window.open(url, "_blank");
  };

  // Determine which image to use based on the type
  let imageUrl;
  switch (type) {
    case "AppStore":
      imageUrl = AppStoreImage;
      break;
    case "GooglePlay":
      imageUrl = GooglePlayImage;
      break;
    default:
      imageUrl = null;
  }

  if (!imageUrl) {
    return null; // Return null if type is invalid
  }

  return (
    <button
      onClick={handleClick}
      style={{
        border: "none",
        background: "none",
        cursor: "pointer",
        padding: 0,
        marginRight: 16,
      }}
    >
      <img
        src={imageUrl}
        alt={type}
        style={{ width: "auto", height: "35px" }}
      />
    </button>
  );
}

export default StoreButton;
