// TopBar.js
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Bar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  background-color: #040714;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
`;

const Logo = styled.img`
  margin-top: 5px;
  height: 30px;
`;

const Buttons = styled.div`
  display: flex;
  margin-right: 16px;
`;

const StyledLink = styled(Link)`
  margin-right: 20px;
  background-color: ${(props) => (props.active ? "#222" : "transparent")};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    background-color: #151c39;
  }

  ${(props) => props.active && "transform: scale(1.1);"}
`;

const TopBar = ({ logoUrl }) => {
  return (
    <Bar>
      <Link to="/">
        <Logo src={logoUrl} alt="Company Logo" />
      </Link>
      <Buttons>
        <StyledLink to="/status-for-digitalocean">Status</StyledLink>
        <StyledLink to="/colosseum-fight-night">CFN</StyledLink>
        <StyledLink to="/contact">Contact</StyledLink>
      </Buttons>
    </Bar>
  );
};

export default TopBar;
