import React from "react";
import styled from "styled-components";
import background from "../../assets/images/particle-bg.jpg";
import StoreButton from "../../components/StoreButton/StoreButton";
import AppImage from "../../components/AppImage/AppImage";
import { TextContainer, Description, PageWrapper } from "../Styles/Page.styled";
import { PrivacyPolicyButton } from "../DigitalOcean/DigitalOcean";

// Styled component for the placeholder page
const PlaceholderPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(${background}) center/cover;
`;

function CfnPage() {
  return (
    <PageWrapper>
      <AppImage
        src={require("../../assets/images/cfn-screenshot-detail.png")}
        alt="Colosseum Fight Night App"
      />
      <TextContainer>
        <h1>Colosseum Fight Night</h1>
        <Description>
          Introducing the Colosseum Fight Night app, powered by React Native!
          Your ultimate sidekick for all CFN events. Discover fighter details,
          match schedules, gym insights, and everything event-related: uncover
          dining spots, grab a drink, and even groove to the current playlist.
          Stay in the loop with real-time updates throughout the event. Download
          now and elevate your CFN experience.
        </Description>
        <StoreButton
          type="AppStore"
          url="https://apps.apple.com/us/app/colosseum-fight-night/id6466671160"
        />
        <StoreButton
          type="GooglePlay"
          url="https://play.google.com/store/apps/details?id=com.colosseumfightnight"
        />
        <div style={{ height: "16px" }}></div>
        <PrivacyPolicyButton to="/colosseum-fight-night/privacy-policy">
          Privacy Policy
        </PrivacyPolicyButton>
      </TextContainer>
    </PageWrapper>
  );
}

export default CfnPage;
