import React from "react";
import styled from "styled-components";
import background from "../../assets/images/particle-bg-large.jpg";

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${background}) center/cover;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #fff;
  max-width: 800px;
  margin: auto;
  line-height: 1.6;
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 0.5em;
  margin-top: 80px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  margin-top: 1em;
  margin-bottom: 0.5em;
`;

const Paragraph = styled.p`
  margin-bottom: 1em;
`;

const ContactInfo = styled.p`
  margin-bottom: 1em;
  a {
    color: #007bff;
    text-decoration: none;
  }
`;

const DigitalOceanPrivacyPolicy = () => {
  return (
    <PageWrapper>
      <Container>
        <Title>Privacy Policy for Status for DigitalOcean</Title>
        <p>
          <strong>Effective Date:</strong> 26.05.2024
        </p>

        <SectionTitle>1. Introduction</SectionTitle>
        <Paragraph>
          Welcome to Status for DigitalOcean, a product of Falcon Delta. Your
          privacy is important to us, and we are committed to protecting it.
          This Privacy Policy explains how we handle your information when you
          use our app.
        </Paragraph>

        <SectionTitle>2. Information We Do Not Collect</SectionTitle>
        <Paragraph>
          We want to assure you that Status for DigitalOcean does not collect
          any personal data or information from its users.
        </Paragraph>

        <SectionTitle>3. Contact Information</SectionTitle>
        <Paragraph>
          If you have any questions or concerns about this Privacy Policy or our
          practices, please contact us at:
        </Paragraph>
        <ContactInfo>
          Email:{" "}
          <a href="mailto:patrick@falcondelta.io">patrick@falcondelta.io</a>
        </ContactInfo>

        <SectionTitle>4. Changes to This Privacy Policy</SectionTitle>
        <Paragraph>
          We may update this Privacy Policy from time to time. If we make any
          changes, we will notify you by updating the effective date at the top
          of this policy. We encourage you to review this policy periodically to
          stay informed about how we are protecting your information.
        </Paragraph>

        <SectionTitle>5. Acceptance of This Policy</SectionTitle>
        <Paragraph>
          By using Status for DigitalOcean, you signify your acceptance of this
          Privacy Policy. If you do not agree to this policy, please do not use
          our app. Your continued use of the app following the posting of
          changes to this policy will be deemed your acceptance of those
          changes.
        </Paragraph>

        <SectionTitle>6. No Data Collection</SectionTitle>
        <Paragraph>
          As mentioned, Status for DigitalOcean does not collect, store, or
          share any personal information or data. Your privacy is fully
          respected and ensured by our app.
        </Paragraph>

        <SectionTitle>7. Third-Party Services</SectionTitle>
        <Paragraph>
          Status for DigitalOcean may contain links to other websites or
          services that are not operated by us. If you click on a third-party
          link, you will be directed to that third party's site. We strongly
          advise you to review the Privacy Policy of every site you visit. We
          have no control over and assume no responsibility for the content,
          privacy policies, or practices of any third-party sites or services.
        </Paragraph>

        <SectionTitle>8. Security</SectionTitle>
        <Paragraph>
          Even though we do not collect any personal data, we are committed to
          ensuring that our app is secure and free from vulnerabilities. We
          regularly update our app to improve security.
        </Paragraph>

        <Paragraph>
          Thank you for using Status for DigitalOcean. We are committed to
          ensuring your privacy and providing a secure experience.
        </Paragraph>
      </Container>
    </PageWrapper>
  );
};

export default DigitalOceanPrivacyPolicy;
