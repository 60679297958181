// AppImage.js

import React from "react";
import styled, { keyframes } from "styled-components";

// Define a keyframe animation for the vertical movement
const moveUpDown = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

const StyledAppImage = styled.img`
  max-height: 400px;
  margin-top: 40px;
  animation: ${moveUpDown} 5s infinite; /* Apply the animation with a slower duration */

  @media screen and (max-width: 768px) {
    /* Apply the following styles only on screens wider than 768px */
    max-width: 260px;
  }
`;

const AppImage = ({ src, alt }) => {
  return <StyledAppImage src={src} alt={alt} />;
};

export default AppImage;
