import React from "react";
import styled from "styled-components";
import logo from "../../../assets/images/home-isometric.png";
import background from "../../../assets/images/particle-bg.jpg";
import Lottie from "lottie-react";
import isometric from "../../../assets/animations/isometric.json";

const SectionContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${background}) center/cover;
  color: white;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Text = styled.h1`
  font-size: 3.5em;
  margin-left: 40px;

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px;
  }
`;

const GradientText = styled.span`
  background: linear-gradient(to right, #479dd6, #57bd70);
  -webkit-background-clip: text;
  color: transparent;
`;

const Logo = styled.img`
  width: auto;
  height: 300px;
  margin-left: 150px;

  @media (max-width: 768px) {
    margin: 20px 0 0;
    height: 200px;
  }
`;

const LottieAnimation = styled(Lottie)`
  width: auto;
  height: 300px;
  margin-left: 150px;

  @media (max-width: 768px) {
    margin: 20px 0 0;
    height: 200px;
  }
`;

const TopSection = ({ id }) => {
  return (
    <SectionContainer id={id}>
      <Text>
        <GradientText>Coding</GradientText>
        <br />
        great experiences
      </Text>
      <LottieAnimation animationData={isometric} />
    </SectionContainer>
  );
};

export default TopSection;
