import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledButtonLink = styled(Link)`
  display: inline-block;
  padding: 10px 20px;
  background-color: #5affac;
  color: #000;
  border: none;
  border-radius: 20px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-shadow: none;

  &:hover {
    background-color: #2abf74;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 8px 16px;
  }
`;
