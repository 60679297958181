// App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import CfnPage from "./pages/CFN/Cfn";
import TopBar from "./components/TopBar/TopBar";
import DigitalOcean from "./pages/DigitalOcean/DigitalOcean";
import Contact from "./pages/Contact/Contact";
import DigitalOceanPrivacyPolicy from "./pages/DigitalOcean/DigitalOceanPrivacyPolicy";
import CfnPrivacyPolicy from "./pages/CFN/CfnPrivacyPolicy";

function App() {
  return (
    <BrowserRouter>
      <TopBar logoUrl={require("./assets/icons/logo-small.png")} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/colosseum-fight-night" element={<CfnPage />} />
        <Route
          path="/colosseum-fight-night/privacy-policy"
          element={<CfnPrivacyPolicy />}
        />
        <Route path="/status-for-digitalocean" element={<DigitalOcean />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/status-for-digitalocean/privacy-policy"
          element={<DigitalOceanPrivacyPolicy />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
