// SectionThree.js
import React from "react";
import styled from "styled-components";

import sectionThreeBg from "../../../assets/images/particle-bg.jpg";

const SectionContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${sectionThreeBg}) center/cover;
`;

const SectionContent = styled.div`
  text-align: center;
  color: white;
  padding: 0 40px;
`;

const EmailLink = styled.a`
  background: linear-gradient(to right, #479dd6, #57bd70);
  -webkit-background-clip: text;
  color: transparent;
  text-decoration: none; /* Remove the underline */
  font-size: 26px; /* Make the text bigger */
  transition: color 0.3s ease-in-out; /* Add smooth color transition on hover */
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;

const RegularText = styled.p`
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 20px;
`;

const ContactSection = ({ id }) => {
  return (
    <SectionContainer id={id}>
      <SectionContent>
        <h2>Ready to turn your ideas into reality?</h2>
        <RegularText>Get in touch.</RegularText>
        <EmailLink href="mailto:patrick@falcondelta.io">
          patrick@falcondelta.io
        </EmailLink>
      </SectionContent>
    </SectionContainer>
  );
};

export default ContactSection;
