import styled from "styled-components";
import background from "../../assets/images/particle-bg.jpg";

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(${background}) center/cover;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  max-width: 400px;
  margin-left: 40px;
  color: white;

  @media screen and (max-width: 768px) {
    /* Apply the following styles only on screens wider than 768px */
    max-width: 300px;
    margin-right: 40px;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
  color: #fff;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
