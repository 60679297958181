import React from "react";
import styled from "styled-components";
import background from "../../assets/images/particle-bg.jpg";
import StoreButton from "../../components/StoreButton/StoreButton";
import AppImage from "../../components/AppImage/AppImage";
import { TextContainer, Description, PageWrapper } from "../Styles/Page.styled";
import SocialButton from "../../components/SocialButton/SocialButton";

const Socials = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const Logo = styled.img`
  width: 400px;
  height: auto;
  margin-right: 40px;

  @media screen and (max-width: 768px) {
    width: 260px;
  }
`;

const EmailLink = styled.a`
  background: linear-gradient(to right, #479dd6, #57bd70);
  -webkit-background-clip: text;
  color: transparent;
  text-decoration: none;
  font-size: 26px;
  transition: color 0.3s ease-in-out;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;

function Contact() {
  return (
    <PageWrapper>
      <Logo
        src={require("../../assets/images/falcon-delta-logo-small.png")}
        alt="Falcon Delta Logo"
      />
      <TextContainer>
        <h1>Contact</h1>
        <Description>
          Got questions or inquiries? Feel free to reach out to us anytime!
          We're here to help. You can email us or connect with us on our social
          networks below.
        </Description>
        <EmailLink href="mailto:patrick@falcondelta.io">
          patrick@falcondelta.io
        </EmailLink>
        <Socials>
          <SocialButton type="instagram" />
          <SocialButton type="threads" />
          <SocialButton type="x-social" />
        </Socials>
      </TextContainer>
    </PageWrapper>
  );
}

export default Contact;
