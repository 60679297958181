import React from "react";
import styled from "styled-components";
import XSocialIcon from "../../assets/icons/x-social.svg";
import InstagramIcon from "../../assets/icons/instagram.svg";
import ThreadsIcon from "../../assets/icons/threads.svg";

// Define styled components
const Button = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: #040714;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
    background-color: #151c39;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

// Define a map for social button parameters
const socialParams = {
  "x-social": {
    image: XSocialIcon,
    alt: "X",
    url: "https://twitter.com/falcondelta_io",
  },
  instagram: {
    image: InstagramIcon,
    alt: "Instagram",
    url: "https://www.instagram.com/falcondelta.io/",
  },
  threads: {
    image: ThreadsIcon,
    alt: "Threads",
    url: "https://www.threads.net/@falcondelta.io",
  },
};

// Define the SocialButton component
const SocialButton = ({ type }) => {
  const { image, alt, url } = socialParams[type] || {};

  if (!image || !alt || !url) {
    return null;
  }

  return (
    <Button href={url} target="_blank" rel="noopener noreferrer">
      <Icon src={image} alt={alt} />
    </Button>
  );
};

export default SocialButton;
