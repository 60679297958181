import React from "react";
import styled from "styled-components";
import sectionTwoBg from "../../../assets/images/particle-bg-rev.jpg";
import { StyledButtonLink } from "../../../styles/GlobalStyles";

const SectionContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${sectionTwoBg}) center/cover;
`;

const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  margin: 0 auto;
`;

const AppContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  max-width: 400px;
  margin-left: 40px;
  color: white;

  @media screen and (max-width: 768px) {
    max-width: 300px;
    text-align: left;
    margin-left: 0;
    margin-right: 10px;
  }
`;

const AppImage = styled.img`
  max-height: 400px;
  margin-top: 40px;

  @media screen and (max-width: 768px) {
    max-width: 150px;
    margin: 20px;
  }
`;

const Heading = styled.h1`
  font-size: 2em;

  @media (max-width: 768px) {
    font-size: 1.5em;
    /* Override the element to be h2 for mobile */
    display: block;
    &::before {
      content: "h2";
      display: none;
    }
  }
`;

const Description = styled.p`
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
  color: #fff;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const CfnSection = ({ id }) => {
  return (
    <SectionContainer id={id}>
      <SectionContent>
        <AppContainer>
          <AppImage
            src={require("../../../assets/images/home-status.png")}
            alt="Colosseum Fight Night App"
          />
          <TextContainer>
            <Heading>Status for DigitalOcean</Heading>
            <Description>
              Stay updated on DigitalOcean services with real-time status
              monitoring, widgets, incident reports, and maintenance schedules.
            </Description>
            <StyledButtonLink to="/status-for-digitalocean">
              Learn more
            </StyledButtonLink>
          </TextContainer>
        </AppContainer>
        <AppContainer>
          <AppImage
            src={require("../../../assets/images/home-cfn.png")}
            alt="Colosseum Fight Night App"
          />
          <TextContainer>
            <Heading>Colosseum Fight Night</Heading>
            <Description>
              Your Fight Night companion. View the fight card, discover
              fighters, gyms, see the match results in real time and navigate
              the event with ease.
            </Description>
            <StyledButtonLink to="/colosseum-fight-night">
              Learn more
            </StyledButtonLink>
          </TextContainer>
        </AppContainer>
      </SectionContent>
    </SectionContainer>
  );
};

export default CfnSection;
